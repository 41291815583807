.fullscreen-background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed; /* Fixed position to cover the entire viewport */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1; /* To ensure it stays behind other content */
}

/*------------- custom styles ---------------*/
.white_09_with_shadow { color:rgba(255, 255, 255, 0.9);text-shadow:0 0 1px rgba(0,0,0,0.3); } 
.w90 { width:90%; } 
.vw10_spacer { width:10vw; }
.opacity_0 { opacity:0; }
.opacity_075 { opacity:0.75; }
.roboto300 { font-family: 'Roboto',sans-serif;font-weight:300; }
.roboto500 { font-family: 'Roboto',sans-serif;font-weight:500; }
.grey { color:rgb(95,99,104); }
.white { color:white; }
.font_075 { font-size: 0.75rem } 
.font_0875 { font-size: 0.875rem } 
.font_095 { font-size: 0.95rem } 
.font_100 { font-size: 1.0rem } 
.font_125 { font-size: 1.25rem } 
.font_15 { font-size: 1.5rem } 
.scrollable_div { overflow-y: auto;max-height:100%; }


.SplashScreen_transparent_modal_content { background-color: transparent !important;border:0; }
.SplashScreen_vertical_flex { display:flex; flex-direction: column; align-items: center;} 
.SplashScreen_waiting_icon_img { width:35%;  }
.SplashScreen_spinner { color:white;margin-top:16px; }
.SplashScreen_loading_data_p { color:white;margin-top:16px; }

.language_switcher_div { display:flex; flex-direction:row; justify-content:center; align-items:center; align-content:center; gap:4px; } 
.language_switcher_button { text-decoration: none !important;color:grey !important; border:none; background-color:transparent !important; font-size:22px; margin:0; padding:0;}

.NavigationBar { position: fixed; top: 91%; left: 0; width: 100%; height: 9%; background: rgba(0, 0, 0, 0.25);  z-index: 50; }
.NavigationBar_nav_icon_img { height:100%; }

.LeaguesView_league_list_container_div { border:0;border-bottom:solid 1px rgba(255,255,255,0.66); }

.Search_input { background-color:rgba(255, 255, 255, 0.5)}
.Search_background_overlay_div { position: absolute; top: 0; left:0; min-width:100%; min-height:100%; z-index: 99;} 
.Search_results_overlay_div { position: absolute; top: 0; z-index: 100; } 
.Search_results_container_div { background: linear-gradient(to bottom, #f5c41f, #f5d463); border-radius:3px 3px 6px 6px; box-shadow:0 0 4px rgba(0, 0, 0, 0.25);}
.Search_close_cross_div { position: absolute; top: 0; left:0; z-index:98; }

.LeagueRow_header_div { border:0;border-top:solid 1px rgba(255,255,255,0.66); }
.LeagueRow_league_icon_img { width:8vw;height:8vw; }
.LeagueRow_league_icon_name_div { width:90vw; }

.DateTimeSeparatorRow_date_row_div { background-color:rgba(255, 255, 255, 0.075 );border-top:1px solid rgba(255,255,255,0.8) }
.DateTimeSeparatorRow_time_row_div { background-color:rgba(255, 255, 255, 0.1 );border-top:1px solid rgba(255,255,255,0.8) }

.MatchRow_div { border:0;border-top:solid 1px rgba(255,255,255,0.66);background-color:rgba(255, 255, 255, 0.125) }

.MatchRow_logo_div { width:33%; }
.MatchRow_logo_img { width:100%; }
.MatchFow_versus_div { width:15vw; }
.MatchRow_favorite_placeholder_div { width:10vw; }
.MatchRow_live_placeholder_div, .MatchRow_navigate_next_img { width:10vw; }
.ClubLogo_logo_img { width:16px;border: yellow 5px solid; }

.MatchRow_live_base_div { position: relative;border-top:solid 1px rgba(255,255,255,0.66) }
.MatchRow_live_container_div { width:90vw; } 
.MatchRow_live_overlay_div { position: absolute; top: 0; left: 0; width: 100%;  height: 100%; z-index: 10; }
.MatchRow_live_in_play_div {  background: linear-gradient(to bottom, #eb212e, #f75456); border-radius:100px; }

@keyframes rotateFootball { from { transform: rotate(0deg); } to { transform: rotate(360deg); } }
.MatchRow_live_football_animation { display: inline-block; /* Needed to apply transform */ animation: rotateFootball 3s linear infinite; }

.Favorites_favorite_icon_filled_img { width:33%; }
.Favorites_list_container_div { border-bottom:solid 1px rgba(255,255,255,0.66); }
.Favorites_favorite_icon_in_container_filled_img { width:15%; }

.MatchDetails_div { position: fixed; top: 0; left: 100%; width: 100%; height: 100%; background: white;  z-index: 1000; transition: left 0.2s ease-in-out, opacity 0.2s ease-in-out; opacity: 0; }
.MatchDetails_div_entering, .MatchDetails_div_entered { left:0;opacity:1; }
.MatchDetails_div_exiting, .MatchDetails_div_exited { left:100%;opacity:0; }
.MatchDetails_navigate_back_img { width:10vw;transform: rotate(180deg); }
.MatchDetails_league_logo_img { width:10vw; }

/* -------- PlayStore.js Component and related styles --------------*/
.playstore_top_bar_container { padding:2vh; }
.playstore_logo_img { width:50%;margin:auto; }
.playstore_app_container { padding-top:3%; }
.playstore_app_icon_container { width:30%; }
.playstore_app_name_container { width:70%; }
.playstore_app_icon_img { width:100%; }
.playstore_app_name_span { font-weight:bold;font-size:130%; }
.playstore_dev_name_span { font-size:100%;color:#01875f; }
.playstore_num_downloads_container { padding:3vh; }
.playstore_downloads_span { font-size:80%;color: rgb(95,99,104); }
.playstore_18_plus_img { width:15%; }
.playstore_install_btn { background-color: #01875f; width:100%; border:0; }
.playstore_all_devices_img { width: 12%; }
.playstore_all_devices_p { font-size:80%; }
.playstore_screenshot_img { width:100%; }
.swipeable_carousel_overlay { position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
                              z-index: 1050; /* higher than most elements */ display: flex; justify-content: center; align-items: center; }
.swipeable_carousel_overlay_img { width:90%; }
.carousel-item { transition: transform 0.2s ease-in-out !important; }
.playstore_updated_at_p { font-size:1em; }
.playstore_tag_p { border-radius:1000px;border:lightgray 1px solid; }
.playstore_privacy_box_div { border:solid lightgray 1px;border-radius:10px; }
.playstore_share_icon_img { width:12%; }
.supportcollapse_btn { border:0 !important; }
.supportcollapse_icon_expand_css_animation { transform: rotate(180deg); transition: transform 0.3s ease; }
.supportcollapse_icon_collapse_css_animation { transform: rotate(0deg); transition: transform 0.3s ease; }
.playstore_footer_link { text-decoration: none; }
.playstore_separator_div { margin:auto;background-color:lightgray; height:1px; }
.playstore_select_lang_btn { border:0;background-color:transparent;padding:0;margin:0; }
/*--------------------*/
